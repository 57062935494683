function banText(event) {
    let banText = parseFloat(event.key);
    let keys = ['ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'End', 'Home', 'Backspace', 'Clear', 'Cut', 'Delete'];
    let isKey = keys.includes(event.key);

    if (isNaN(banText)) {
        if (!isKey) {
            event.preventDefault();
        }
    }
}

function banNumbers(event) {
    let banText = event.key;
    let isNumber = /^\d$/.test(banText);

    if (isNumber) {
        event.preventDefault();
    }
}

function removeSpaces(str) {
    return str.replace(/\s+/g, '');
}

function getMaxPrice(item) {
    var id = item.id,
        that = $(item),
        parent = that.closest('.filters-row_inputs'),
        inputs = parent.find('input'),
        msg = parent.find('.msg'),
        newItem;

    inputs.removeClass('validate-border');
    msg.hide();

    switch (id) {
        case 'min_price':
            newItem = $('#max_price');

            if (newItem.val().length > 0) {
                $(that).attr('max', newItem.val())
            }

            break;

        case 'max_price':
            newItem = $('#min_price');

            if (newItem.val().length > 0) {
                $(that).attr('min', newItem.val())
            }

            break;

        case 'min_quadrature':
            newItem = $('#max_quadrature');

            if (newItem.val().length > 0) {
                $(that).attr('max', newItem.val())
            }

            break;

        case 'max_quadrature':
            newItem = $('#min_quadrature');

            if (newItem.val().length > 0) {
                $(that).attr('min', newItem.val())
            }

            break;
    }
}

// для сравнения мин и макс инпутов
function comparePricesValues(item) {
    let id = item.id,
        that = $(item),
        parent = that.closest('.filters-row_inputs'),
        inputs = parent.find('input'),
        msg = parent.find('.msg'),
        max, min, value;

    msg.hide();
    inputs.removeClass('validate-border');

    switch (id) {
        case 'min_price':
            max = parseFloat(that.attr('max'));
            value = parseFloat(that.val());

            if (value >= max) {
                that.addClass('validate-border')
                that.val(max)
                msg.show().text('Значение "От" не может быть больше или равно, чем "До"')
            }

            break;

        case 'max_price':
            min = parseFloat(that.attr('min'));
            value = parseFloat(that.val());

            if (value <= min) {
                that.addClass('validate-border')
                that.val(min)
                msg.show().text('Значение "До" не может быть больше или равно, чем "От"')
            }

            break;

        case 'min_quadrature':
            max = parseFloat(that.attr('max'));
            value = parseFloat(that.val());

            if (value >= max) {
                that.addClass('validate-border')
                that.val(max)
                msg.show().text('Значение "До" не может быть больше или равно, чем "От"')
            }

            break;

        case 'max_quadrature':
            min = parseFloat(that.attr('min'));
            value = parseFloat(that.val());

            if (value <= min) {
                that.addClass('validate-border')
                that.val(min)
                msg.show().text('Значение "До" не может быть больше или равно, чем "От"')
            }

            break;

    }
}

// функция получения гет параметров

function getParams(url = window.location) {
    let params = {};

    new URL(url).searchParams.forEach(function (val, key) {
        if (params[key] !== undefined) { // Проверяем параметр на undefined
            /* Проверяем, имеется ли в объекте аналогичный params[key]
            *  Если его нет, то добавляем его в объект
            */
            if (!Array.isArray(params[key])) {
                params[key] = [params[key]];
            }
            params[key].push(val);
        } else {
            params[key] = val;
        }
    });
    // console.log(params);
    return params;
}

// получить стили элемента
function getStyle(elem) {
    if (window.getComputedStyle) return getComputedStyle(elem, null);
    else return elem.currentStyle;
}

// получить куки
function getCookie(name) {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }

    return null;
}

// меняем куки
function setCookie(name, value) {
    document.cookie = name + "=" + encodeURIComponent(value) + "; path=/";
}

// склонение слов

function pluralizeWord(number, wordForms) {
    // Проверяем, что передан массив форм слова
    if (!Array.isArray(wordForms) || wordForms.length !== 3) {
        throw new Error('wordForms должен быть массивом из трех форм слова');
    }

    // Проверяем, что передано число
    if (typeof number !== 'number') {
        throw new Error('number должен быть числом');
    }

    // Определяем индекс формы слова в зависимости от числа
    let formIndex;
    if (number % 10 === 1 && number % 100 !== 11) {
        formIndex = 0; // первая форма (например, "яблоко")
    } else if ([2, 3, 4].includes(number % 10) && ![12, 13, 14].includes(number % 100)) {
        formIndex = 1; // вторая форма (например, "яблока")
    } else {
        formIndex = 2; // третья форма (например, "яблок")
    }

    // Возвращаем склоненное слово
    return wordForms[formIndex];
}

// обработка ошибок ajax

function ajaxErrors(jqXHR, exception) {
    if (jqXHR.status === 0) {

        // console.log('Not connect. Verify Network.');
        console.log('Нет соединения. Проверьте сеть.');

    } else if (jqXHR.status == 404) {

        console.log('Запрошенная страница не найдена (404).');
        // console.log('Requested page not found (404).');

    } else if (jqXHR.status == 500) {

        console.log('Внутренняя ошибка сервера (500).');
        // console.log('Internal Server Error (500).');

    } else if (exception === 'parsererror') {

        console.log('Не удалось выполнить запрошенный синтаксический анализ JSON. (parse failed)');
        // console.log('Requested JSON parse failed.');

    } else if (exception === 'timeout') {

        console.log('Превышен лимит вермени на запрос.');
        // console.log('Time out error.');

    } else if (exception === 'abort') {

        console.log('Запрос прерван.');
        // console.log('Ajax request aborted.');

    } else {

        console.log('Неопределенная ошибка. ' + jqXHR.responseText);
        // console.log('Uncaught Error. ' + jqXHR.responseText);

    }
}

// функция валидации имени

function formNameValid(el, elCheck) {
    if (el.val().length === 0) {
        elCheck = false;
        el.parent().find('.msg').hide();
        el.parent().find('.msg-empty').show();
        el.addClass('validate-border');
    } else if (el.val().length < 2) {
        elCheck = false;
        el.parent().find('.msg').hide();
        el.parent().find('.msg-error').show();
        el.addClass('validate-border');
    } else {
        elCheck = true;
        el.parent().find('.msg').hide();
        el.removeClass('validate-border');
    }

    return elCheck;
} // конец

// функция валидации телефона

function formPhoneValid(el, elCheck) {
    var validPhone = /_/;
    if (el.val().length === 0) {
        elCheck = false;
        el.addClass('validate-border');
        el.parent().find('.msg').hide();
        el.parent().find('.msg-empty').show();
    } else {
        if (el.val().length > 0 && validPhone.test(el.val())) {
            elCheck = false;
            el.addClass('validate-border');
            el.parent().find('.msg').hide();
            el.parent().find('.msg-error').show();
        } else {
            if (el.val().length > 0 && !validPhone.test(el.val())) {
                elCheck = true;

                el.removeClass('validate-border');
                el.parent().find('.msg').hide();
            } else {
                elCheck = false;
            }
        }
    }

    return elCheck;
} // конец

// функция валидации email

function formEmailValid(el, elCheck, emailRequired) {
    let emailVal = el.val(),
        emailValid = emailVal.split('@'),
        l = el.val().length;


    if (emailRequired) {
        if (l === 0) {
            el.addClass('validate-border');
            el.parent().find('.msg').hide();
            el.parent().find('.msg-empty').show();
            elCheck = false;
        } else {
            if (l > 0 && (el.val().match(/.+?\@.+\.+/g) || []).length === 1) {
                if (emailValid[0].length < 3) {
                    el.addClass('validate-border');
                    el.parent().find('.msg').hide();
                    el.parent().find('.msg-empty').show();
                    elCheck = false;
                } else {
                    el.removeClass('validate-border');
                    el.parent().find('.msg').hide();
                    elCheck = true;
                }
            } else {
                el.addClass('validate-border');
                el.parent().find('.msg').hide();
                el.parent().find('.msg-error').show();
                elCheck = false;
            }
        }
    } else {
        if (l > 0) {
            if (l > 0 && (el.val().match(/.+?\@.+\.+/g) || []).length === 1) {
                if (emailValid[0].length < 3) {
                    el.addClass('validate');
                    el.parent().find('.msg').hide();
                    el.parent().find('.msg-empty').show();
                    elCheck = false;
                } else {
                    el.removeClass('validate');
                    el.parent().find('.msg').hide();
                    elCheck = true;
                }
            } else {
                el.addClass('validate-border');
                el.parent().find('.msg').hide();
                el.parent().find('.msg-error').show();
                elCheck = false;
            }
        } else {
            elCheck = true
        }
    }

    return elCheck;
} // конец

function checkboxValid(el, elCheck, isRequired) {
    if (isRequired) {
        if (el.prop('checked')) {
            elCheck = true;
            el.parent().removeClass('not-checked');
        } else {
            elCheck = false;
            el.parent().addClass('not-checked');
        }
    } else {
        elCheck = true;
        el.parent().removeClass('not-checked');
    }

    return elCheck;
}

function captchaValid(oldValue, newValue) {
    return oldValue !== newValue;
}

// измерение ширины input
function measureInputWidth(inputEl) {
    const inputValue = inputEl.value;
    const parent = inputEl.parentElement;

    // Создаем временный элемент span для измерения ширины текста
    const tempSpan = document.createElement('span');
    tempSpan.classList.add('input_span_spoof');
    tempSpan.innerHTML = inputValue;
    parent.appendChild(tempSpan);

    // Получаем ширину текста
    const inputWidth = tempSpan.offsetWidth;

    // Удаляем временный элемент
    parent.removeChild(tempSpan);

    // Возвращаем ширину текста в пикселях

    return inputWidth;
}

// клик вне элемента
function clickOutside(el, btn, cl) {
    let element = typeof el === 'object' ? el : document.querySelector(el);
    let button = typeof btn === 'object' ? btn : document.querySelector(btn);

    document.addEventListener('click', e => {
        let target = e.target;
        let itsEl = target == element || element.contains(target);
        let its_btn = target == button;
        let its_el_is_open = element.classList.contains(cl);

        if (!itsEl && !its_btn && its_el_is_open) {
            element.classList.toggle(cl);
        }
    });
}

function handleClickOutside(element, callback) {

    function handleDocumentClick(event) {
        const isClickInsideElement = element.contains(event.target);
        if (!isClickInsideElement) {
            callback();
        }
    }

    document.addEventListener("click", handleDocumentClick);

    return function cleanup() {
        document.removeEventListener("click", handleDocumentClick);
    };
}

function customSelect(containers, title, content, label, radio, show) {
    const allContainers = document.querySelectorAll(containers);

    function calculateLabelHeight(labels, show) {
        let labelHeight = 0;

        for (let i = 0; i < labels.length; i++) {
            if (i < show) {
                labelHeight += labels[i].offsetHeight;
            } else {
                break;
            }
        }

        return labelHeight;
    }

    allContainers.forEach(function (container) {
        const labels = container.querySelectorAll(label);
        const contentEl = container.querySelector(content);
        const titleEl = container.querySelector(title);
        const labelHeight = calculateLabelHeight(labels, show);

        if (show) {
            if (labels.length <= show) {
                contentEl.classList.add('no-scroll');
            }

            contentEl.style.maxHeight = labelHeight + 32 + 'px';
        }

        container.addEventListener('click', function(event) {
            const mainParent = event.target.closest(containers);
            const currentTitle = title.replaceAll('.', '');
            const currentRadio = radio.replaceAll('.', '');
            const currentLabel = label.replaceAll('.', '');

            if (event.target.classList.contains(currentRadio) || event.target.classList.contains(currentLabel)) {
                const content = event.target.textContent;

                mainParent.classList.add('active');
                titleEl.textContent = content;
                mainParent.classList.remove('open');

            } else if (event.target.classList.contains(currentTitle)) {

                if (!mainParent.classList.contains('open')) {
                    mainParent.classList.add('open');
                } else {
                    mainParent.classList.remove('open');
                }

                allContainers.forEach(container => {
                    if (container !== mainParent) {
                        container.classList.remove('open');
                    }
                });
            }
        }, true);

    })

    document.addEventListener('click', function(event) {
        const target = event.target;

        if (!target.closest(containers)) {
            allContainers.forEach(container => {
                container.classList.remove('open');
            });
        }
    });
}


function validation(form, values) {
    var parent = $(form),
        parentNew = document.querySelector(form),
        formName = parent.find('.input-name'),
        formPhone = parent.find('.input-phone'),
        formEmail = parent.find('.input-email'),
        formMethod = parent.find('.input-method'),
        formCaptcha = $('#captcha-container'),
        formInput = parent.find('input:not(input[type="submit"], input[type="hidden"], input[type="radio"], input[type="checkbox"]), textarea'),
        formAgreement = parent.find('input[type="checkbox"][name="agreement"]'),
        formSubmit = parent.find('.btn_submit'),
        nameCheck = true, phoneCheck = true, emailCheck = true,
        formMethodCheck = true, agreementCheck = false, checkArr;

    let captchaToken = null;
    let captchaCheck = true;

    formInput.val('');
    formAgreement.prop('checked', false);

    // капча

    if (formCaptcha) {

        smartCaptchaInit();

        function callback(token) {
            // console.log(token);

            if (token) {
                captchaCheck = captchaValid(token, captchaToken);
                captchaToken = token;
            }

            allValidationCheck();
            submitBtnEnabled();
        }

        function smartCaptchaInit() {
            if (!window.smartCaptcha) {
                return;
            }

            window.smartCaptcha.render('captcha-container', {
                sitekey: 'ysc1_YmwaGih5AJBgLg5sHIbIJSienbVhA6JiW8snHJEZ9c9699af',
                callback: callback,
                // test: true
            });
        }
    }

    // конец капчи

    if (formName.length > 0) nameCheck = false;
    if (formPhone.length > 0) phoneCheck = false;
    if (formEmail.length > 0) emailCheck = false;
    if (formMethod.length > 0) formMethodCheck = true;
    if (formCaptcha.length > 0) captchaCheck = false;

    // функция проверки значений всех результатов валидвации

    function allValidationCheck() {
        checkArr = [];
        checkArr.push(nameCheck);
        checkArr.push(phoneCheck);
        checkArr.push(emailCheck);
        checkArr.push(formMethodCheck);
        checkArr.push(agreementCheck);
        checkArr.push(captchaCheck);

        return checkArr.indexOf(false) === -1;
    }

    function eventsValidateFields() {
        // валидация полей при потере фокуса
        if (formName) {
            formName.on('blur', function () {
                nameCheck = formNameValid(formName, nameCheck);
            })
        }

        if (formPhone) {
            formPhone.on('blur', function () {
                phoneCheck = formPhoneValid(formPhone, phoneCheck);
            })
        }

        if (formEmail) {
            formEmail.on('blur', function () {
                emailCheck = formEmailValid(formEmail, emailCheck, true)
            })
        }

        if (formAgreement) {
            formAgreement.on('change', function () {
                agreementCheck = checkboxValid(formAgreement, agreementCheck, true)
            })
        }
    }

    function validateFields() {
        // валидация полей при потере фокуса
        if (formName.length > 0) nameCheck = formNameValid(formName, nameCheck);

        if (formPhone.length > 0) phoneCheck = formPhoneValid(formPhone, phoneCheck);

        if (formEmail.length > 0) emailCheck = formEmailValid(formEmail, emailCheck, true);
    }

    eventsValidateFields();

    function submitBtnEnabled() {
        // console.log(checkArr)
        if (allValidationCheck()) {
            formSubmit.removeClass('disabled');
        } else {
            formSubmit.addClass('disabled');
        }
    }

    // разблокировка кнопки отправки при потере фокуса
    formInput.on('blur', function () {
        submitBtnEnabled();
    });

    formAgreement.on('change', function () {
        submitBtnEnabled();
    });

    // отправка формы при условии (условие на всякий случай)

    parent.off('submit')
    parent.on('submit', function (e) {
        e.preventDefault();

        validateFields();

        if (allValidationCheck()) {
            formSubmit.removeClass('disabled');
        } else {
            formSubmit.addClass('disabled');

            return false;
        }

        var myData;

        switch (form) {
            case '#feedback_modal_form':
                myData = {
                    userName: formName.val().trim(),
                    userEmail: formEmail.val().trim(),
                    userPhone: formPhone.val().trim(),
                    callMethod: formMethod.val().trim(),
                }

                // console.log(myData)

                $.ajax({
                    // url: the_keys.ajaxurl,
                    url: 'https://jsonplaceholder.typicode.com/posts',
                    type: 'post',
                    data: myData,
                    dataType: 'json',
                    beforeSend: function (xhr) {
                        $('body').addClass('loader');
                    },
                    success: function (result) {
                        console.log(result);
                        // if (result['status'] === 'success') {
                        $('body').removeClass('loader');
                        $(form).closest('.modal').fadeOut(0);
                        document.body.classList.remove('no-scroll')
                        // }

                        $('#modal_feedback_thx').fadeIn(215);
                    }
                });

                break;
        }
    });
}
;

$(document).ready(function () {

    // init custom select

    customSelect('.select', '.select-title', '.select-content', '.select-content__wrapper', '.select-content__radio');

    // растягиваем main между header&footer

    const mainBody = $('body');
    const headerH = $('header').outerHeight();
    const headerStyle = getStyle(document.querySelector('.header'));
    const footerH = $('footer').outerHeight();
    const desktop = 1340;
    const laptop = 1359.98;
    const tablet = 1029.98;
    const mobile = 807.98;
    const fullMobile = 644.98;
    const tagMain = document.getElementsByTagName('main');
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    var mainHeight;

    if (headerStyle.position === 'absolute') {
        mainHeight = screenHeight - footerH + headerH;
        tagMain[0].style.minHeight = mainHeight + 'px';

        let sectionOwners = document.querySelector('.owners');

        if (sectionOwners) {
            sectionOwners.style.minHeight = mainHeight + 'px';
        }
    } else {
        mainHeight = screenHeight - (footerH + headerH);
        tagMain[0].style.minHeight = mainHeight + 'px';
    }

    // попапы

    $(document).on('click', '.modal, .modal__close', function () {
        let fields = $('.modal input:not(input[type="submit"], input[type="hidden"], input[type="radio"], input[type="checkbox"], .modal textarea)');
        let radioCheckFields = $('.modal input[type="radio"], .modal input[type="checkbox"]')

        $('.modal').fadeOut(215);

        $('.modal .msg').hide();
        fields.removeClass('validate');
        fields.val('');
        radioCheckFields.prop('checked', false);
        document.body.classList.remove('no-scroll')
    });

    $(document).on('click', '.modal > .modal__content', function (e) {
        e.stopPropagation();
    });

    $(document).on('click', 'button.detail__btn', function (e) {
        e.preventDefault();

        $('#feedback_modal').fadeIn(215);
        validation('#feedback_modal_form');

        if (!$(this).hasClass('not-modal')) {
            document.body.classList.add('no-scroll')
        }

    }); // конец

    if ($('#owners_form').length > 0) validation('#owners_form');

    // hamburger menu

    const hamburger = document.querySelector('.header__hamburger');
    const hamburgerWrap = document.querySelector('.header__wrapper');
    const hamburgerClose = document.querySelector('.hamburger-wrap__close');

    hamburger.addEventListener('click', function (e) {
        e.preventDefault();

        hamburgerWrap.classList.add('open');
        document.body.classList.add('no-scroll');
    });

    hamburgerClose.addEventListener('click', function (e) {
        e.preventDefault();

        hamburgerWrap.classList.remove('open');
        document.body.classList.remove('no-scroll');
    });

    // фиксированная шапка

    function fixedHeader(item) {
        if ($(item).scrollTop() > headerH) {
            mainBody.addClass('nav_scroll');
        } else {
            mainBody.removeClass('nav_scroll');
        }
    }

    fixedHeader(window);

    $(window).scroll(function () {
        fixedHeader(this)
    }); // конец

    // табы на главной

    const mainCatalogMobile = window.matchMedia(`(max-width: 644.98px)`).matches;

    function showCatalogList(status, space) {
        // console.log(status, space)

        document.querySelectorAll('.main-catalog__list').forEach(function (list, index) {
            if (list.dataset.rent === status && list.dataset.space === space) {
                list.style.display = 'flex';

                if (mainCatalogMobile) {
                    initMainCatalogSlider(list, index);
                }
            } else {
                list.style.display = 'none'

                if (mainCatalogMobile && list.classList.contains('slick-initialized')) {
                    $(list).slick('unslick');
                }
            }
        });
    }

    if (mainCatalogMobile) {

        $('.main-catalog__tabs_status').on('change', 'input', function (e) {
            let status = $(this).val();
            let space = $('.main-catalog__tabs_space').find('input:checked').val();

            space = space ? space : 'office';

            showCatalogList(status, space);
        });

        $('.main-catalog__tabs_space').on('change', 'input', function (e) {
            let space = $(this).val();
            let status = $('.main-catalog__tabs_status').find('input:checked').val();

            showCatalogList(status, space);
        });

    } else {
        $('.main-catalog__tabs_status').on('click', 'li', function (e) {
            let status = $(this).data('tab');
            let space = $('.main-catalog__tabs_space').find('li.active').data('tab');

            $('.main-catalog__tabs_status').find('li').removeClass('active');
            $(this).addClass('active')

            showCatalogList(status, space);
        });

        $('.main-catalog__tabs_space').on('click', 'li', function (e) {
            let space = $(this).data('tab');
            let status = $('.main-catalog__tabs_status').find('li.active').data('tab');

            $('.main-catalog__tabs_space').find('li').removeClass('active');
            $(this).addClass('active')

            showCatalogList(status, space);
        });
    }


    // слайдер каталога на главной и похожих предложений в деталке

    function initMainCatalogSlider(item, index) {
        // console.log(item)
        $(item).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 700,
            draggable: true,
            arrows: false,
            dots: true,
            dotsClass: `slider-dots slider-dots-${index}`,
            appendDots: $('.main-catalog__navs'),
            infinite: false,
            swipe: true,
        });
    }

    if (mainCatalogMobile) {

        const catalogLists = document.querySelectorAll('.main-catalog__list');

        if (catalogLists) {
            initMainCatalogSlider(catalogLists[0], 0);
        }

        $('.more-objects__list').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 700,
            draggable: true,
            arrows: false,
            dots: true,
            dotsClass: 'slider-dots',
            appendDots: $('.more-objects__list-nav'),
            infinite: false,
            swipe: true,
        });
    }

    function catalogItemsSliderInside(items) {
        if (items) {
            items.forEach(function (item, index) {

                $(item).find('.catalog-item__images').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 700,
                    draggable: true,
                    arrows: false,
                    dots: true,
                    dotsClass: 'slider-dots',
                    appendDots: $(item).find('.catalog-item__images-navs'),
                    variableWidth: true,
                    infinite: true,
                    swipe: true,
                });
            });
        }

    }

    // слайдер в деталке

    // стикеры
    const stickers = document.querySelectorAll('.sticker');

    if (stickers) {
        stickers.forEach(function (el) {
            clickOutside(el, el.parent, 'show');
        });
    }

    const catalogItems = document.querySelectorAll('.catalog-item-wrap');
    if (window.matchMedia(`(min-width: ${tablet}px)`).matches) {

        catalogItemsSliderInside(catalogItems);

    } else {
        $('.catalog-item__stickers').on('click', '.sticker', function (e) {
            e.preventDefault();
            $(this).toggleClass('show');
        });

        $('.detail__slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 700,
            draggable: true,
            arrows: false,
            dots: true,
            dotsClass: 'slider-dots',
            appendDots: $('.detail__slider-navs'),
            variableWidth: true,
            infinite: false,
            swipe: true,
        });

        let section = $('section');

        if (section.is('.catalog')) {

            catalogItemsSliderInside(catalogItems);

        } else if (section.is('.detail') || section.is('.main-catalog')) {

            if (!window.matchMedia(`(max-width: ${fullMobile}px)`).matches) {

                catalogItemsSliderInside(catalogItems);

            }
        }
    }

    $('#max_price, #min_price, #max_quadrature, #min_quadrature').val('') // очищаем значение ипутов при загрузке страницы, чтобы единицы измерения не сбивались


    function unitTextPosition() {
        let parent = this.parentElement; // родитель элемента
        let unitWrap = parent.querySelector('.value-unit'); // блок с единицей измерения
        let inputWidth = measureInputWidth(this); // получаем ширину инпута
        let inputStyle = getStyle(this); // получаем стили инпута
        let inputStyleWidth = parseFloat(inputStyle.width); // получеам ширину инпута
        let inputStylePaddingL = parseFloat(inputStyle.paddingLeft); // получаем внутреннй отсуп инпута
        let inputStylePaddingR = parseFloat(inputStyle.paddingRight); // получаем внутреннй отсуп инпута
        let inputsRentSale = document.querySelector('input[name="rent_sale"]:checked'); // находим выбранную радио кнопку, отвечающую за выбор аренда/продажа
        let inputId = this.getAttribute('id'); // получаем id элемента

        // if (inputId === 'min_price' || inputId === 'max_price') {
        //     // проверяем, чтобы инпут был "цена"
        //
        //     if (inputsRentSale.value === 'rent') {
        //         // если радиокнопка в позиции "аренда"
        //
        //         if (inputWidth + 45 > inputStyleWidth - inputStylePaddingR) {
        //             unitWrap.style.left = inputStyleWidth - inputStylePaddingR + 5 + 'px';
        //         } else {
        //             unitWrap.style.left = inputWidth + inputStylePaddingL + 5 + 'px';
        //         }
        //
        //     } else if (inputsRentSale.vallue === 'sale') {
        //         // если радиокнопка в позиции "покупка"
        //
        //         if (inputWidth + 45 > inputStyleWidth - inputStylePaddingR) {
        //             unitWrap.style.left = inputStyleWidth - inputStylePaddingR + 5 + 'px';
        //         } else {
        //             unitWrap.style.left = inputWidth + inputStylePaddingL + 5 + 'px';
        //         }
        //
        //     }
        // } else {
        //
        //     if (inputWidth + 45 > inputStyleWidth - inputStylePaddingR) {
        //         unitWrap.style.left = inputStyleWidth - inputStylePaddingR + 5 + 'px';
        //     } else {
        //         unitWrap.style.left = inputWidth + inputStylePaddingL + 5 + 'px';
        //     }
        //
        // }

        if (inputWidth + 45 > inputStyleWidth - inputStylePaddingR) {
            unitWrap.style.left = inputStyleWidth - inputStylePaddingR + 5 + 'px';
        } else {
            unitWrap.style.left = inputWidth + inputStylePaddingL + 5 + 'px';
        }
    }

    function listenerForInputsWithUnit(input) {
        input.addEventListener('keypress', unitTextPosition);
        input.addEventListener('keyup', unitTextPosition);
        input.addEventListener('input', unitTextPosition);
    }

    const elementsWithUnit = document.querySelectorAll('.form__row-wrap_with-uint');

    if (elementsWithUnit) {
        elementsWithUnit.forEach(function (item) {
            let itemInput = item.querySelector('input');

            listenerForInputsWithUnit(itemInput)
        });
    }

    $('input[name="min_quadrature"]').val('');

    $('.form__row-wrap_with-uint').on('focus', 'input', function (e) {
        $(this).parent().addClass('show');
    })
        .on('blur', 'input', function (e) {
            if ($(this).val().length === 0) {
                $(this).parent().removeClass('show');
            }
        });

    // маски

    $('.input-phone').inputmask({
        mask: "+7 (X99) 999-99-99",
        definitions: {
            'X': {
                validator: "9",
                placeholder: "9"
            }
        }
    });

    $(document).on('click', '.stickers__close', function (e) {
        $(this).parent().hide();
    });

    $(document).on('click', '.catalog-item', function (event) {
        let target = event.target;
        let condition_one = target.closest('.catalog-item__images-navs') !== null;
        let condition_two = target.classList.contains('slider-dots');

        if (condition_one || condition_two) {
            event.preventDefault();
        }
    });

    $('#about_link').on('click', function (e) {
        e.preventDefault();
        let anchor = $(this).find('a').attr('href');

        if ($('section').is(anchor)) {
            $('html,body').stop().animate({scrollTop: $(anchor).offset().top}, 1000);
            hamburgerWrap.classList.remove('open');
            document.body.classList.remove('no-scroll');
        } else {
            window.location = window.location.origin + anchor;
        }
    });

    $('.input-name').on('keyup', function (event) {
        let value = event.target.value;
        value = value.replace(/\d/g, '');
        this.value = value;
    });

    //

var groups, myMap, barsContent, MyBalloonLayout, MyBalloonContentLayout, myGeoObject;

const getImagePath = () => {
    return window.location.href + 'img/bars'
};

groups = [
    // паркинг Новотель
    {
        center: [55.748386, 37.537345],
        geometryCord: [
            [
                [55.74917601253537, 37.537422063383836],
                [55.74903078201953, 37.536955359015444],
                [55.74894908961604, 37.53668177369561],
                [55.748876474001946, 37.53652084115474],
                [55.748767550326036, 37.53639209512232],
                [55.74865862634459, 37.53629553559753],
                [55.748513393888246, 37.53623116258099],
                [55.74839993065245, 37.53623116258099],
                [55.74830462128994, 37.5362633490892],
                [55.74813215612369, 37.53637600186803],
                [55.74796876735829, 37.53655302766273],
                [55.74787799552869, 37.53674614671221],
                [55.74780537790659, 37.537068011793515],
                [55.74778722348093, 37.537277224096876],
                [55.74778722348093, 37.53743815663756],
                [55.74781445511577, 37.53761518243274],
                [55.74785984113144, 37.53779220822784],
                [55.74791430428024, 37.53795314076891],
                [55.74796876735212, 37.538130166563555],
                [55.7480958475514, 37.538564684424124],
                [55.748640472299265, 37.537985327276765],
                [55.74917601253537, 37.537422063383836]
            ]
        ],
        color: '#a5a5a5',
        image: '',
        type: 'parking',
        alt: '',
        title: 'Новотель',
        subtitle: 'паркинг',
        floors: 0,
        address: 'Пресненская наб., 2'
    },
    // паркинг Империя II
    {
        center: [55.747165, 37.541225],
        geometryCord: [
            [
                [55.74690495266257, 37.540675850561165],
                [55.74731117918523, 37.541881846654356],
                [55.74735202713075, 37.54187380002732],
                [55.74804189705618, 37.54116569684737],
                [55.747637961546886, 37.53995870279067],
                [55.747597113902, 37.53995870279067],
                [55.74690495266257, 37.540675850561165]
            ]
        ],
        color: '#a5a5a5',
        image: '',
        type: 'parking',
        alt: '',
        title: 'Империя II',
        subtitle: 'паркинг',
        floors: 0,
        address: 'Пресненская наб., 4'
    },
    // паркинг Тестовская
    // закомментирую, а то хер их знает, вдруг потом скажут добавить
    /*{
        center: [55.748954, 37.534294],
        geometryCord: [
            [
                [55.74810023244229, 37.53325520687399],
                [55.74857110631093, 37.53313249581153],
                [55.74862556846244, 37.53312243752772],
                [55.7486584726357, 37.533118414214194],
                [55.748692511412784, 37.533114390900685],
                [55.74870499229024, 37.53311237924392],
                [55.74873562715428, 37.533114390900685],
                [55.74878895444626, 37.533116402557425],
                [55.74902949368412, 37.53312646084123],
                [55.74907828212273, 37.533128472498],
                [55.74919287797544, 37.53312444918449],
                [55.7493278964187, 37.53312243752772],
                [55.74947199121487, 37.533118414214194],
                [55.74948901025072, 37.53311237924392],
                [55.749504894677465, 37.53310634427362],
                [55.74952191369263, 37.53309829764658],
                [55.74953893270661, 37.53309025101954],
                [55.74955368251272, 37.53308421604927],
                [55.749562759313676, 37.5330761694222],
                [55.74957297071222, 37.533068122795164],
                [55.749584316707484, 37.53305605285459],
                [55.749593393495, 37.533043982914045],
                [55.74960133568785, 37.53303191297347],
                [55.74965125800568, 37.53295748167329],
                [55.74965579638245, 37.53290316694076],
                [55.74965920017411, 37.53288506202989],
                [55.74966260396548, 37.53286896877581],
                [55.7496671423535, 37.5328488522082],
                [55.74967281533778, 37.53283074729735],
                [55.74967565181708, 37.532801662093256],
                [55.74967697550692, 37.532772576889215],
                [55.74967640820545, 37.53274483278976],
                [55.749672815325226, 37.53271708869026],
                [55.749670357014864, 37.53269986387925],
                [55.74966789872947, 37.53268263906822],
                [55.74966354943632, 37.53266407315271],
                [55.749659200148976, 37.53265087165516],
                [55.749655039954426, 37.53263431739639],
                [55.749650123367424, 37.532620445346645],
                [55.74964029019781, 37.53259806566522],
                [55.74963045700058, 37.532579038744984],
                [55.74962213661272, 37.532570195837174],
                [55.749616085426304, 37.532562694033835],
                [55.74960993966549, 37.53255820971562],
                [55.7496022811304, 37.53255372539745],
                [55.749588476842156, 37.532551462283564],
                [55.749579210945704, 37.53254982781247],
                [55.74956805405365, 37.53254819334137],
                [55.74954952226134, 37.532548947712634],
                [55.74950943304623, 37.53255313866427],
                [55.74807085676418, 37.53294250503813],
                [55.74810023244229, 37.53325520687399]
            ]
        ],
        color: '#a5a5a5',
        image: '',
        type: 'parking',
        alt: '',
        title: 'Тестовская улица',
        subtitle: 'паркинг',
        floors: 0,
        address: 'Тестовская ул. (под эстакадой)'
    },
     */
    // паркинг OKO II
    {
        center: [55.748954, 37.534294],
        geometryCord: [
            [
                [55.75044228, 37.53515447],
                [55.75049068, 37.53530467],
                [55.75044228, 37.53534759],
                [55.75066011, 37.53607715],
                [55.75069642, 37.53603423],
                [55.75078113, 37.53627027],
                [55.75075693, 37.53631318],
                [55.75101107, 37.53712857],
                [55.75067222, 37.53747190],
                [55.75053909, 37.53749335],
                [55.75047858, 37.53732169],
                [55.75034546, 37.53745044],
                [55.75030915, 37.53745044],
                [55.75028495, 37.53740752],
                [55.75026074, 37.53736461],
                [55.75024864, 37.53732169],
                [55.75023654, 37.53721440],
                [55.75023654, 37.53715003],
                [55.75026074, 37.53708566],
                [55.75036966, 37.53695691],
                [55.75034546, 37.53687108],
                [55.75026074, 37.53695691],
                [55.75017603, 37.53672088],
                [55.75026074, 37.53661359],
                [55.75024864, 37.53657067],
                [55.75018813, 37.53663505],
                [55.75012762, 37.53667796],
                [55.75005501, 37.53665650],
                [55.75003080, 37.53661359],
                [55.75000660, 37.53654922],
                [55.75000660, 37.53646339],
                [55.75001870, 37.53639901],
                [55.75005501, 37.53633464],
                [55.75013972, 37.53624881],
                [55.74995819, 37.53564799],
                [55.75044228, 37.53515447],
            ]
        ],
        color: '#a5a5a5',
        image: '',
        type: 'parking',
        alt: '',
        title: 'OKO II',
        subtitle: 'паркинг',
        floors: 0,
        address: '1-й Красногвардейский пр., 19'
    },
    // паркинг Империя
    {
        center: [55.748137, 37.540192],
        geometryCord: [
            [
                [55.74810770636985, 37.541205929982574],
                [55.74866594641817, 37.54061852620823],
                [55.74821776651626, 37.53930491434327],
                [55.74769015569101, 37.53990237640132],
                [55.74810770636985, 37.541205929982574]
            ]
        ],
        color: '#a5a5a5',
        image: '',
        type: 'parking',
        alt: '',
        title: 'Империя',
        subtitle: 'паркинг',
        floors: 0,
        address: 'Пресненская набережная, 6, стр. 2'
    },
    // Империя
    {
        center: [55.748179, 37.540245],
        geometryCord: [
            [
                [55.74818990, 37.53958650],
                [55.74848036, 37.54055210],
                [55.74811991, 37.54089885],
                [55.74782944, 37.53993325]
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/empire.png`,
        type: 'tower',
        alt: 'Империя',
        title: 'Империя',
        subtitle: '',
        floors: 60,
        address: 'Пресненская набережная, 6, стр. 2'
    },
    // Эволюция
    {
        center: [55.748439, 37.541690],
        geometryCord: [
            [
                [55.74816507, 37.54176628],
                [55.74849185, 37.54138004],
                [55.74869759, 37.54204522],
                [55.74838292, 37.54238855],
                [55.74816507, 37.54176628],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/evo.png`,
        type: 'tower',
        alt: 'Эволюция',
        title: 'Эволюция',
        subtitle: '',
        floors: 54,
        address: 'Пресненская набережная, 4, стр. 2'
    },
    // Город столиц Москва
    {
        center: [55.747220, 37.538727],
        geometryCord: [
            [
                [55.74746549, 37.53869660],
                [55.74712578, 37.53894680],
                [55.74695633, 37.53838890],
                [55.74729522, 37.53817432]
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/moscow.png`,
        type: 'tower',
        alt: 'Город столиц',
        title: 'Город столиц',
        subtitle: 'Москва',
        floors: 76,
        address: 'Пресненская набережная, 8, стр. 1'
    },
    // Город столиц Санкт-Петербург
    {
        center: [55.747220, 37.538727],
        geometryCord: [
            [
                [55.74782775, 37.53834598],
                [55.74802140, 37.53898972],
                [55.74768252, 37.53935450],
                [55.74746549, 37.53869660]
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/saint-petersburg.png`,
        type: 'tower',
        alt: 'Город столиц',
        title: 'Город столиц',
        subtitle: 'Санкт-Петербург',
        floors: 65,
        address: 'Пресненская набережная, 8, стр. 1'
    },
    // Блок А
    {
        center: [55.746593, 37.537380],
        geometryCord: [
            [
                [55.74705250, 37.53758699],
                [55.74648365, 37.53715784],
                [55.74636262, 37.53727586],
                [55.74647760, 37.53750116],
                [55.74654719, 37.53757627],
                [55.74665310, 37.53764600],
                [55.74674084, 37.53767819],
                [55.74690121, 37.53768892],
                [55.74705250, 37.53758699],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/block-a.png`,
        type: 'tower',
        alt: 'Башня на Набережной',
        title: 'Башня на Набережной',
        subtitle: 'Блок А',
        floors: 60,
        address: 'Пресненская набережная, 10'
    },
    // Блок B
    {
        center: [55.746466, 37.536365],
        geometryCord: [
            [
                [55.74628464, 37.53689923],
                [55.74668405, 37.53616967],
                [55.74667195, 37.53597655],
                [55.74656302, 37.53601947],
                [55.74649040, 37.53608384],
                [55.74640567, 37.53623404],
                [55.74633305, 37.53636279],
                [55.74628464, 37.53655591],
                [55.74628464, 37.53689923],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/block-b.png`,
        type: 'tower',
        alt: 'Башня на Набережной',
        title: 'Башня на Набережной',
        subtitle: 'Блок B',
        floors: 60,
        address: 'Пресненская набережная, 10'
    },
    // Блок C
    {
        center: [55.746466, 37.536365],
        geometryCord: [
            [
                [55.74691401, 37.53608384],
                [55.74687770, 37.53634133],
                [55.74688981, 37.53657737],
                [55.74692612, 37.53681340],
                [55.74697453, 37.53698506],
                [55.74705925, 37.53717818],
                [55.74708346, 37.53709235],
                [55.74710766, 37.53709235],
                [55.74714397, 37.53704944],
                [55.74719239, 37.53715672],
                [55.74725290, 37.53698506],
                [55.74726501, 37.53677049],
                [55.74727711, 37.53657737],
                [55.74724080, 37.53642716],
                [55.74719239, 37.53621259],
                [55.74713187, 37.53608384],
                [55.74704715, 37.53595509],
                [55.74702294, 37.53610530],
                [55.74696243, 37.53610530],
                [55.74691401, 37.53608384],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/block-c.png`,
        type: 'tower',
        alt: 'Башня на Набережной',
        title: 'Башня на Набережной',
        subtitle: 'Блок C',
        floors: 60,
        address: 'Пресненская набережная, 10'
    },
    // IQ-квартал
    {
        center: [55.747535, 37.534276],
        geometryCord: [
            [
                [55.74797953, 37.53404734],
                [55.74808801, 37.53499021],
                [55.74810011, 37.53574123],
                [55.74785805, 37.53584852],
                [55.74691446, 37.53471252],
                [55.74718073, 37.53436920],
                [55.74727755, 37.53460524],
                [55.74765275, 37.53419754],
                [55.74797953, 37.53404734],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/iq.png`,
        type: 'tower',
        alt: 'IQ-квартал',
        title: 'IQ-квартал',
        subtitle: '',
        floors: 22,
        address: 'Пресненская набережная, 10, стр. 2'
    },
    // ОКО Towers
    {
        center: [55.749380, 37.534092],
        geometryCord: [
            [
                [55.74912704, 37.53399165],
                [55.74945380, 37.53364833],
                [55.74988949, 37.53349813],
                [55.75031307, 37.53472121],
                [55.74999658, 37.53505570],
                [55.74970554, 37.53413879],
                [55.74933036, 37.53452502],
                [55.74912704, 37.53399165],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/oko.png`,
        type: 'tower',
        alt: 'ОКО Towers',
        title: 'ОКО Towers',
        subtitle: '',
        floors: 85,
        address: '1-й Красногвардейский пр-д, 21, стр. 1'
    },
    // ОКО II
    {
        center: [55.750535, 37.536535],
        geometryCord: [
            [
                [55.75077046806983, 37.53701126985919],
                [55.750371099237476, 37.535723809532044],
                [55.75012905552258, 37.53593838625323],
                [55.75054052894054, 37.537268761924615],
                [55.75077046806983, 37.53701126985919]
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/oko3.png`,
        type: 'tower',
        alt: 'ОКО II',
        title: 'ОКО II',
        subtitle: '',
        floors: 49,
        address: '1-й Красногвардейский проезд, 19'
    },
    // Федерация Запад
    {
        center: [55.749633, 37.537434],
        geometryCord: [
            [
                [55.74968597, 37.53678771],
                [55.74959416, 37.53663091],
                [55.74946103, 37.53654507],
                [55.74932790, 37.53652362],
                [55.74914637, 37.53658799],
                [55.74914637, 37.53686694],
                [55.74917057, 37.53710297],
                [55.74923108, 37.53738192],
                [55.74926686, 37.53754460],
                [55.74938841, 37.53744630],
                [55.74954704, 37.53727059],
                [55.74965269, 37.53701890],
                [55.74968597, 37.53678771]
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/fed.png`,
        type: 'tower',
        alt: 'Башня Федерация',
        title: 'Башня Федерация',
        subtitle: 'Запад',
        floors: 62,
        address: 'Пресненская набережная, 12'
    },
    // Федерация Восток
    {
        center: [55.749633, 37.537434],
        geometryCord: [
            [
                [55.74986750, 37.53708811],
                [55.74981200, 37.53716735],
                [55.74959416, 37.53751067],
                [55.74948523, 37.53783254],
                [55.74943182, 37.53805371],
                [55.74953766, 37.53814905],
                [55.74970760, 37.53821826],
                [55.74990123, 37.53819680],
                [55.75007825, 37.53813294],
                [55.75008277, 37.53789639],
                [55.75005404, 37.53759650],
                [55.74997385, 37.53729558],
                [55.74986750, 37.53708811],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/fed.png`,
        type: 'tower',
        alt: 'Башня Федерация',
        title: 'Башня Федерация',
        subtitle: 'Восток',
        floors: 95,
        address: 'Пресненская набережная, 12'
    },
    // Башня «ВТБ»
    {
        center: [55.748914, 37.535475],
        geometryCord: [
            [
                [55.74851549, 37.53394745],
                [55.74866072, 37.53394745],
                [55.74864862, 37.53381871],
                [55.74866072, 37.53375433],
                [55.74867283, 37.53373288],
                [55.74869703, 37.53371142],
                [55.74873334, 37.53373288],
                [55.74876965, 37.53375433],
                [55.74891488, 37.53401183],
                [55.74895119, 37.53409766],
                [55.74936909, 37.53553661],
                [55.74932068, 37.53568681],
                [55.74922386, 37.53551515],
                [55.74917545, 37.53570827],
                [55.74912704, 37.53581556],
                [55.74906652, 37.53585847],
                [55.74900601, 37.53590139],
                [55.74863083, 37.53547223],
                [55.74863083, 37.53527911],
                [55.74848560, 37.53527911],
                [55.74848560, 37.53461393],
                [55.74849770, 37.53461393],
                [55.74851549, 37.53394745],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/vtb_merk.jpg`,
        type: 'tower',
        alt: 'Башня «ВТБ»',
        title: 'Башня «ВТБ»',
        subtitle: '',
        floors: 72,
        address: 'Пресненская набережная, 10, стр. 1'
    },
    // Меркурий
    {
        center: [55.750318, 37.539517],
        geometryCord: [
            [
                [55.75063591, 37.53895248],
                [55.75070852, 37.53897393],
                [55.75082954, 37.53929580],
                [55.75080534, 37.53946746],
                [55.75047858, 37.53983224],
                [55.75036966, 37.53983224],
                [55.75021233, 37.53998244],
                [55.75009131, 37.53987516],
                [55.75010341, 37.53961766],
                [55.75024864, 37.53944600],
                [55.75027285, 37.53929580],
                [55.75063591, 37.53895248],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/merkury.png`,
        type: 'tower',
        alt: 'Меркурий Тауэр',
        title: 'Меркурий Тауэр',
        subtitle: '',
        floors: 75,
        address: '1-й Красногвардейский проезд, 15'
    },
    // Северная Башня
    {
        center: [55.750570, 37.532753],
        geometryCord: [
            [
                [55.75031377, 37.53318981],
                [55.75174180, 37.53298514],
                [55.75174180, 37.53309243],
                [55.75215326, 37.53302805],
                [55.75216536, 37.53289931],
                [55.75229848, 37.53289931],
                [55.75232268, 37.53287785],
                [55.75245580, 37.53287785],
                [55.75248000, 37.53285639],
                [55.75262522, 37.53283493],
                [55.75263732, 37.53247015],
                [55.75202014, 37.53208391],
                [55.75099149, 37.53221266],
                [55.75099149, 37.53249161],
                [55.75088257, 37.53249161],
                [55.75088257, 37.53251307],
                [55.75076155, 37.53266327],
                [55.75058002, 37.53266327],
                [55.75058002, 37.53268473],
                [55.75048320, 37.53268473],
                [55.75045900, 37.53272764],
                [55.75030167, 37.53272764],
                [55.75031377, 37.53318981],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/sev.jpg`,
        type: 'tower',
        alt: 'Северная Башня',
        title: 'Северная Башня',
        subtitle: '',
        floors: 27,
        address: 'ул. Тестовская, 10'
    },
    // Neva 1
    {
        center: [55.751133, 37.533724],
        geometryCord: [
            [
                [55.75085408, 37.53342617],
                [55.75136236, 37.53336179],
                [55.75139867, 37.53404844],
                [55.75086618, 37.53411281],
                [55.75085408, 37.53394115],
                [55.75082988, 37.53394115],
                [55.75081777, 37.53366220],
                [55.75084198, 37.53364074],
                [55.75085408, 37.53342617],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/neva.jpg`,
        type: 'tower',
        alt: 'Neva Towers',
        title: 'Neva Towers',
        subtitle: 'Башня 1',
        floors: 68,
        address: '1-й Красногвардейский проезд, вл. 17-18'
    },
    // Neva 2
    {
        center: [55.751584, 37.535305],
        geometryCord: [
            [
                [55.75142287, 37.53499258],
                [55.75170121, 37.53497112],
                [55.75171331, 37.53567922],
                [55.75165280, 37.53567922],
                [55.75164070, 37.53572214],
                [55.75154389, 37.53574359],
                [55.75153179, 37.53570068],
                [55.75144707, 37.53570068],
                [55.75142287, 37.53499258],
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/neva.png`,
        type: 'tower',
        alt: 'Neva Towers',
        title: 'Neva Towers',
        subtitle: 'Башня 2',
        floors: 79,
        address: '1-й Красногвардейский проезд, вл. 17-18'
    },
    // Башня 2000
    {
        center: [55.74612, 37.546274],
        geometryCord: [
            [
                [55.7460609954383, 37.546084904264305],
                [55.746055176371335, 37.54611649541937],
                [55.74601054471667, 37.546525532294126],
                [55.7461557865235, 37.54692384033285],
                [55.74620041801132, 37.54694127469145],
                [55.746248075306134, 37.54694529800496],
                [55.74630178425711, 37.54693591027345],
                [55.746349441427576, 37.5469077470788],
                [55.74639029038432, 37.54687019615259],
                [55.74643340868093, 37.54681521086778],
                [55.74646442356645, 37.54675486116494],
                [55.74648560444966, 37.5466918292531],
                [55.746508298240265, 37.54661940960969],
                [55.74651964513059, 37.54654296665277],
                [55.746552707389654, 37.54611363024259],
                [55.74641427521894, 37.54572873324895],
                [55.746364348741274, 37.54571666330838],
                [55.7463166915891, 37.54571666330838],
                [55.74626070252963, 37.54572548825636],
                [55.74622514869188, 37.5457348759879],
                [55.746191107752885, 37.545753651451015],
                [55.74615782325037, 37.545789190720456],
                [55.74612529518628, 37.545839482139485],
                [55.746101844704576, 37.54589379687204],
                [55.74608255478161, 37.545964204858684],
                [55.74607045129562, 37.54603394229306],
                [55.7460609954383, 37.546084904264305]
            ]
        ],
        color: '#757575',
        image: `${getImagePath()}/tower2000.jpg`,
        type: 'tower',
        alt: 'Башня 2000',
        title: 'Башня 2000',
        subtitle: '',
        floors: 32,
        address: 'Набережная Тараса Шевченко, 23А'
    },
    // Дом Дау
    {
        center: [55.751974, 37.545303],
        geometryCord: [
            [
                [55.75201146413547, 37.542915833976686],
                [55.75248039973792, 37.54450906613152],
                [55.75252275493291, 37.54446615078728],
                [55.75254620153259, 37.544537229326195],
                [55.752947817004625, 37.54415769675053],
                [55.75284722443565, 37.54381973841469],
                [55.75280940761538, 37.54385326602734],
                [55.752677804800065, 37.54341606595796],
                [55.752773859776994, 37.54331950643341],
                [55.75252048589952, 37.54241962530891],
                [55.75201146413547, 37.542915833976686]
            ]
        ],
        color: '#757575',
        image: null,
        type: 'tower',
        alt: 'Дом Дау',
        title: 'Дом Дау',
        subtitle: '',
        floors: 85,
        address: 'Краснопресненская набережная, 14/15'
    },
]

const wordForms = ['этаж', 'этажа', 'этажей'];
const map = document.getElementById('map')
if (map) {
    ymaps.ready(init);

    function init() {
        // Создание карты.
        myMap = new ymaps.Map("map", {
            center: [55.74866991, 37.53878930],
            zoom: 15,
            controls: []
        }, {
            searchControlProvider: 'yandex#search'
        });


        // for (let i = 0; i < groups.length; i++) {
        groups.forEach(function (group) {
            if (group['subtitle'].length > 0) {
                barsContent = '<div class="item__subtitle">';
                barsContent += group['subtitle'];
                barsContent += '</div>';
                barsContent += '<div class="item__address">';
            } else {
                barsContent = '<div class="item__address">';
            }

            barsContent += group['address'];
            barsContent += '</div>';

            if (group['floors'] !== 0) {
                barsContent += '<div class="item__floors">';
                barsContent += group['floors'];
                barsContent += ' ';
                barsContent += pluralizeWord(group['floors'], wordForms);
                barsContent += '</div>';
            }

            MyBalloonLayout = ymaps.templateLayoutFactory.createClass(`
            <div class="bars-content__item-wrapper ${group['type']}">
                <a class="bars-content__item-close" href="#"></a>
                <div class="bars-content__item">
                    $[[options.contentLayout observeSize minWidth=235 maxWidth=maxContent maxHeight=350]]
                </div>
                
                <div class="bars-content__parking-inf">
                    <div class="parking-inf-close"></div>
                
                    <div class="parking-inf__title">
                        Империя II
                    </div>
                    
                    <div class="parking-inf__description">
                        Паркинг
                    </div>
                </div>
            </div>
            `, {
                build: function () {
                    this.constructor.superclass.build.call(this);

                    this._$element = $('.bars-content__item-wrapper', this.getParentElement());

                    this.applyElementOffset();

                    this._$element.find('.bars-content__item-close')
                        .on('click', $.proxy(this.onCloseClick, this));

                    this._$element.find('.parking-inf-close')
                        .on('click', $.proxy(this.onParkingCloseClick, this));

                    myMap.events.add('click', this.onMapClick.bind(this));
                },
                clear: function () {
                    this._$element.find('.bars-content__item-close')
                        .off('click');

                    this.constructor.superclass.clear.call(this);
                },
                onSubLayoutSizeChange: function () {
                    MyBalloonLayout.superclass.onSubLayoutSizeChange.apply(this, arguments);

                    if (!this._isElement(this._$element)) {
                        return;
                    }

                    this.applyElementOffset();

                    this.events.fire('shapechange');
                },
                applyElementOffset: function () {
                    this._$element.css({
                        inset: 0,
                        margin: 'auto'
                    });
                },
                onCloseClick: function (e) {
                    e.preventDefault();

                    this.events.fire('userclose');
                },
                onParkingCloseClick: function (e) {
                    e.preventDefault();
                    let target = e.target;
                    let parent = target.parentElement;

                    parent.classList.add('not-show');
                },
                onMapClick: function (e) {
                    if (!$(e.target).hasClass('bars-content__item-wrapper')) {
                        this.events.fire('userclose');
                    }
                },
                getShape: function () {
                    if (!this._isElement(this._$element)) {
                        return MyBalloonLayout.superclass.getShape.call(this);
                    }

                    var position = this._$element.position();

                    return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                        [position.left, position.top], [
                            position.left + this._$element[0].offsetWidth,
                            position.top + this._$element[0].offsetHeight + this._$element.find('.bars-content__item-triangle')[0].offsetHeight
                        ]
                    ]));
                },
                _isElement: function (element) {
                    return element && element[0] && element.find('.bars-content__item-triangle')[0];
                }
            });

            const imageItem = group['image'] === null ? `${getImagePath()}/photo-plug.png` : group['image'];

            // Создание макета содержимого балуна
            MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
                `<div class="bars-content__item-image">
                    <img src="${imageItem}" alt="${group['alt']}">
                </div>
                <div class="bars-content__item-wrap">
                    <div class="bars-content__item-title">
                        $[properties.balloonHeader]
                    </div>
                    
                    <div class="bars-content__item-inf">
                        $[properties.balloonContent]
                    </div>
                </div>`
            );

            myGeoObject = new ymaps.GeoObject({
                geometry: {
                    type: "Polygon",
                    coordinates: group['geometryCord'],
                    // Задаем правило заливки внутренних контуров по алгоритму "nonZero".
                    fillRule: "nonZero"
                },
                // Описываем свойства геообъекта.
                properties: {
                    // Содержимое балуна.
                    balloonHeader: group['title'],
                    balloonContent: barsContent,
                    hintContent: group['alt'],
                }
            }, {
                fillColor: group['color'],
                strokeColor: group['color'],
                strokeWidth: 3,
                strokeStyle: 'solid',
                balloonShadow: false,
                balloonLayout: MyBalloonLayout,
                balloonContentLayout: MyBalloonContentLayout,
                balloonPanelMaxMapArea: 0
            });

            myGeoObject.events.add('click', function (e) {
                let coords = e.get('coords');

                myMap.balloon.open(coords, myGeoObject.properties.get('balloonContent'), {
                    layout: MyBalloonLayout,
                    contentLayout: MyBalloonContentLayout,
                    panelMaxMapArea: 0
                });
                myMap.panTo(coords, {
                    flying: true, // Плавное перемещение к центру балуна
                    duration: 500 // Длительность перемещения в миллисекундах
                });
            });

            // Добавляем многоугольник на карту.
            myMap.geoObjects.add(myGeoObject);
        });


        // myMap.events.add('boundschange', function() {
        //     myMap.balloon.setPosition(myMap.getCenter());
        //     // myMap.center(myMap.getCenter());
        // });
    }
}
;
const filters = $('.catalog__filters');

filters.find('input[name="rent_sale"][value="rent"]').prop('checked', true);

filters.on('reset', function () {
    let parent = $(this).closest('.catalog__filters');

    document.body.classList.add('loader');
    parent.find('.select').removeClass('active');

    cards.forEach(function (card) {
        card.style.order = 'unset';
        card.style.display = 'block';
        card.classList.remove('onFilter');
    });

    setTimeout(function () {
        document.body.classList.remove('loader');
    }, 1000);
});

filters.on('submit', function (e) {
    e.preventDefault();

    let filtersValues = [];
    let filtersRow = filters.find('.filters-row');

    filtersRow.each(function (i) {
        if (!$(this).hasClass('filters-row_inputs')) {

            if (!$(this).hasClass('filters-row__btn')) {

                let checkedValue = $(this).find('input:checked');

                if (checkedValue.length > 0) {

                    filtersValues.push({
                        name: checkedValue.attr('name'),
                        value: checkedValue.val()
                    });
                } else {
                    if (i !== filtersRow - 1) {
                        let firstInput = $(this).find('input').first();

                        filtersValues.push({
                            name: firstInput.attr('name'),
                            value: null
                        });
                    }
                }

            }

        } else {
            let min = $(this).find('input').first();
            let max = $(this).find('input').last();
            let name = $(this).find('input').attr('name');

            for (let key in cardsValues) {
                let regex = new RegExp(key);
                let isMatch = regex.test(name);

                if (!isMatch) {
                    continue;
                } else {
                    const maxValue = Math.max(...cardsValues[key].map(item => item.value));
                    const minValue = Math.min(...cardsValues[key].map(item => item.value));

                    filtersValues.push({
                        name: min.attr('name'),
                        value: min.val() ? min.val() : minValue
                    });

                    filtersValues.push({
                        name: max.attr('name'),
                        value: max.val() ? max.val() : maxValue
                    });
                }

                // console.log(key)
            }
        }
    });

    // let filterKeys = Object.keys(filtersValues)
    catalogFilter(
        filtersValues[0]['value'],
        filtersValues[1]['value'],
        filtersValues[2]['value'],
        filtersValues[3]['value'],
        filtersValues[4]['value'],
        filtersValues[5]['value'],
        filtersValues[6]['value'],
        filtersValues[7]['value']
    )
    // console.log(filtersValues);
});

filters.on('change', 'input[name="rent_sale"]', function () {
    let value = $(this).val();
    let minPrice = $('#min_price');
    let maxPrice = $('#max_price');
    let inputsParent = $(this).closest('.filters-row_inputs');
    let inputMinUnit = minPrice.parent().find('.value-unit');
    let inputMaxUnit = maxPrice.parent().find('.value-unit');

    minPrice.val('');
    maxPrice.val('');

    minPrice.closest('.form__row-wrap_with-uint').removeClass('show');
    maxPrice.closest('.form__row-wrap_with-uint').removeClass('show');

    inputMinUnit.css('left', '65px');
    inputMaxUnit.css('left', '65px');

    function changePriceUnit(unit) {
        let minPriceParent = minPrice.parent();
        let maxPriceParent = maxPrice.parent();

        minPriceParent.find('.value-unit').text(unit)
        maxPriceParent.find('.value-unit').text(unit)
    }

    const changePlaceholder = (el, placeholder) => {
        el[0].setAttribute('placeholder', placeholder);
    }

    if (value === 'rent') {
        changePriceUnit('₽/мес');
        changePlaceholder(minPrice, 'от 150 000 ₽/мес');
        changePlaceholder(maxPrice, 'до 2 000 000 ₽/мес');
        inputsParent.addClass('unit-rent');
        inputsParent.removeClass('unit-sale');
    } else if (value === 'sale') {
        changePriceUnit('₽/м²');
        changePlaceholder(minPrice, 'от 150 000 ₽/м²');
        changePlaceholder(maxPrice, 'до 2 000 000 ₽/м²');
        inputsParent.addClass('unit-sale');
        inputsParent.removeClass('unit-rent');
    }

});

var cards = document.querySelectorAll('.catalog-item-wrap');
var cardsValues = {
    price: [],
    quadrature: []
};

cards.forEach(function (card, index) {
    let itemChild = card.querySelector('.catalog-item');
    let itemPrice = card.querySelector('.catalog-item__price').textContent;

    cardsValues['price'].push({
        value: parseFloat(removeSpaces(itemPrice)),
        index: index
    });

    cardsValues['quadrature'].push({
        value: parseFloat(itemChild.dataset['quadrature']),
        index: index
    });
});

const square = cardsValues['quadrature'].map((val) => val.value);
const price = cardsValues['price'].map((val) => val.value);
const minSquare = Math.min(...square);
const maxSquare = Math.max(...square);
const minPriceEl = Math.min(...price);
const maxPriceEl = Math.max(...price);

function catalogFilter(
    status,
    space,
    place,
    minQuadrature = minSquare,
    maxQuadrature = maxSquare,
    minPrice = minPriceEl,
    maxPrice = maxPriceEl,
    sorting = null
) {

    cards.forEach(function (card) {
        let itemChild = card.querySelector('.catalog-item');
        let itemStatus = itemChild.dataset['status'];
        let itemSpace = itemChild.dataset['space'];
        let itemPlace = itemChild.dataset['place'];
        let itemQuadrature = parseFloat(itemChild.dataset['quadrature']);
        let itemPrice = card.querySelector('.catalog-item__price').textContent;

        document.body.classList.add('loader');

        itemPrice = parseFloat(removeSpaces(itemPrice));

        if ((status ? status === itemStatus : true) &&
            (place ? (place === itemPlace || place === 'all') : true) &&
            (space ? (space === itemSpace || space === 'all') : true) &&
            (minPrice <= itemPrice && itemPrice <= maxPrice) &&
            (minQuadrature <= itemQuadrature && itemQuadrature <= maxQuadrature)) {

            card.style.display = 'block';
            card.classList.add('onFilter');

        } else {

            card.style.display = 'none';
            card.classList.remove('onFilter');

        }  // проверка всех введенных значений фильтра

        if (sorting === null) {
            card.style.order = 'unset';
        }
    });

    if (sorting !== null) {
        let sortingArray;

        switch (sorting) {
            case 'max_price':
                sortingArray = cardsValues['price'].slice().sort(function (a, b) {
                    return b.value - a.value
                });

                break;

            case 'min_price':
                sortingArray = cardsValues['price'].slice().sort(function (a, b) {
                    return a.value - b.value
                });

                break;
        }

        for (let i = 0; i < sortingArray.length; i++) {
            let sortingIndex = sortingArray[i]['index'];

            if (cards[sortingIndex].classList.contains('onFilter')) {
                cards[sortingIndex].style.order = i;
            }
        }
    }

    const onFilterItems = document.querySelectorAll('.catalog-item-wrap.onFilter');

    if (onFilterItems.length > 0) {
        document.querySelector('.catalog__list_empty').style.display = 'none';
        document.querySelector('.catalog__more').style.display = 'flex';
    } else {
        document.querySelector('.catalog__list_empty').style.display = 'flex';
        document.querySelector('.catalog__more').style.display = 'none';
    }

    setTimeout(function () {
        document.body.classList.remove('loader');
    }, 1000);
}

var backLink = document.querySelector('.catalog__back-top');

backLink.addEventListener('click',function (e) {
    e.preventDefault();

    $('html,body').stop().animate({scrollTop: 0}, 1000);
})

function catalogMoreEvent(items, perPage, page, pages, button) {
    button.setAttribute('data-page', page);


    items.forEach(function (item, index) {
        if (index + 1 > perPage * page) {
            item.style.display = 'none';
        } else {
            item.style.display = 'block';
        }
    });

    if (page > pages) {
        button.style.display = 'none';
        backLink.style.display = 'flex';
    } else {
        button.style.display = 'flex';
        backLink.style.display = 'none';
    }
}

const catalogMore = document.querySelector('.catalog__more');

if (catalogMore) {

    const catalogPageItems = document.querySelectorAll('.catalog-item-wrap');
    let getParamsPage = parseFloat(getParams()['page']);
    let catalogMaxItems = catalogPageItems.length;
    let catalogPerPage = 6;
    let catalogPages = catalogMaxItems / 6;


    if (isNaN(getParamsPage)) {
        catalogPageItems.forEach(function (item, index) {
            if (index + 1 > catalogPerPage) {
                item.style.display = 'none';
            } else {
                item.style.display = 'block';
            }
        });
    } else {
        catalogMoreEvent(catalogPageItems, catalogPerPage, getParamsPage, catalogPages, catalogMore);
    }


    document.querySelector('.catalog__more').addEventListener('click', function (e) {
        e.preventDefault();
        document.body.classList.add('loader');

        let catalogPage = parseFloat(this.dataset.page);

        catalogPage++

        catalogMoreEvent(catalogPageItems, catalogPerPage, catalogPage, catalogPages, this)

        let href = window.location.origin + window.location.pathname + '?page=' + catalogPage
        window.history.replaceState(null, null, href);

        setTimeout(function () {
            document.body.classList.remove('loader');
        }, 1000);
    });
}
;
$('.main-hero__form').on('submit', function (e) {
    e.preventDefault();
    var formData = new FormData(this),
        action = $(this).attr('action'),
        formDataArr = [];

    for (var pair of formData.entries()) {
        formDataArr.push({
            [pair[0]]: pair[1]
        })
        localStorage.setItem(pair[0], pair[1])
    }

    localStorage.setItem('formData', formDataArr);
    window.location.pathname = action;
}); // при отправке формы на главной записываем данные в localStorage

$(document).on('click', '.main-catalog__link', function (e) {
    e.preventDefault();

    var parent = $(this).closest('section'),
        href = $(this).attr('href'),
        rent_sale = parent.find('.main-catalog__tabs_status li.active').data('tab'),
        space = parent.find('.main-catalog__tabs_space li.active').data('tab');

    localStorage.setItem('space', space)
    localStorage.setItem('rent_sale', rent_sale)

    window.location.pathname = href;
});

if ($('section').is('.catalog')) {
    let space = localStorage.getItem('space');
    let place = localStorage.getItem('place');
    let status = localStorage.getItem('rent_sale');
    let min_square = localStorage.getItem('min_quadrature');

    if (space !== null && place !== null && status !== null && min_square !== null) {
        min_square = min_square.length > 0 ? min_square : minSquare;
        // console.log(min_square)
        catalogFilter(
            status,
            space,
            place,
            min_square,
            maxSquare,
            minPriceEl,
            maxPriceEl
        );

        for (let key in localStorage) {
            var value = localStorage[key],
                item, text;

            switch (key) {
                case 'place':
                    item = $(document).find('input[name="' + key + '"][value="' + value + '"]');
                    text = item.closest('.select-content__wrapper').find('.select-content__radio').text();
                    item[0].checked = true;
                    item.closest('.select').find('.select-title').text(text);
                    item.closest('.select').addClass('active');
                    localStorage.removeItem(key)

                    break

                case 'space':
                    item = $(document).find('input[name="' + key + '"][value="' + value + '"]');
                    text = item.closest('.select-content__wrapper').find('.select-content__radio').text();
                    item[0].checked = true;
                    item.closest('.select').find('.select-title').text(text);
                    item.closest('.select').addClass('active');
                    localStorage.removeItem(key)

                    break

                case 'min_quadrature':
                    item = $(document).find(`#${key}`);
                    item.val(value);
                    localStorage.removeItem(key)

                    break

                case 'rent_sale':
                    item = $(document).find('input[name="' + key + '"][value="' + value + '"]');
                    item[0].checked = true;
                    localStorage.removeItem(key)

                    break
            } // подставляем параметры фильтра
        }
    }
} // на странице каталога проверяем localStorage на данные для фильтра
;

});
